import router from "@/router/index";

function navigateToLocation(locationReference) {
  console.log("router navigateToLocation ", locationReference);
  router.push({
    name: "Location",
    params: { locationReference: locationReference },
  });
}

export { navigateToLocation };
