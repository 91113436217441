<script lang="ts" setup>
import { ref, computed, watch, onMounted, getCurrentInstance } from "vue";
import { getInstallationDetails, patchInstallationDetails, postInstallationDetails } from "@/services/api/installation.api";
import {
  getInstallationGroups,
  getInstallationTypes,
  getInstallationProductTypes,
  getInstallationControlTypes,
  getInstallationDetailTypes,
  getInstallationSubDetailTypes,
  getInstallationCollaborationTypes,
  getInstallationDeliveryTypes,
  getInstallationInputSources,
  severityCode,
} from "@/services/api/masterdata.api";
import { ValidationObserver, ValidationProvider, setInteractionMode } from "vee-validate";
import { emptyGuid } from "@/composables/miscellaneous.js";
import useDetailEdit from "@/composables/detailEdit.js";
import { navigateToLocation } from "@/composables/general.js";
import _ from "lodash";
import DateInput from "@/components/general/DateInput.vue";

const {
  setMode,
  setReadonlyMode,
  setEditMode,
  setEditTLMode,
  setDetails,
  hasDetails,
  selectNewIndex,
  selectedData,
  updateSelectedDetail,
  resetSelectedDetail,
  isReadOnly,
  isAddingMode,
  isAddingMasterMode,
  isAddingDetailMode,
  isEditTLMode,
  allowEdit,
  allowEditTL,
  addNewDetailMode,
  detailTabs,
  modDateFormatted,
  checkDateValid,
} = useDetailEdit();

setInteractionMode("eager");

const props = defineProps({
  id: String,
  locationReference: String,
  addModus: {
    type: Boolean,
    default: false,
  },
});

const types = ref([]);
const groups = ref([]);
const productTypes = ref([]);
const controlTypes = ref([]);
const detailTypes = ref([]);
const subDetailTypes = ref([]);
const collaborationTypes = ref([]);
const deliveryTypes = ref([]);
const inputSources = ref([]);

const allProductTypes = ref([]);
const allControlTypes = ref([]);
const allDetailTypes = ref([]);
const allSubDetailTypes = ref([]);
const allCollaborationTypes = ref([]);
const allDeliveryTypes = ref([]);
const allInputSources = ref([]);

const selectedType = ref(undefined);

const selectedTabIndex = ref(undefined);
const dataMustBeRetrieved = ref(false);

const typesLoaded = ref(false);
const groupsLoaded = ref(false);
const productTypesLoaded = ref(false);
const controlTypesLoaded = ref(false);
const detailTypesLoaded = ref(false);
const subDetailTypesLoaded = ref(false);
const collaborationTypesLoaded = ref(false);
const deliveryTypesLoaded = ref(false);
const inputSourcesLoaded = ref(false);
const isSaving = ref(false);

const masterDataLoaded = computed(
  () =>
    groupsLoaded.value &&
    typesLoaded.value &&
    productTypesLoaded.value &&
    controlTypesLoaded.value &&
    detailTypesLoaded.value &&
    subDetailTypesLoaded.value &&
    collaborationTypesLoaded.value &&
    deliveryTypesLoaded.value &&
    inputSourcesLoaded.value
);

const placeholderType = computed(
  () =>
      selectedType.value == 'Onbekend' ? "Selecteer eerst een geldig Type" : ""
    );

watch(
  () => [props.id, props.addModus],
  () => {
    // console.log("InstallationDetails, watch id, ", props.id, selectedType.value);
    setMode(props.id, props.addModus);
    if (!isAddingMasterMode.value) {
      dataMustBeRetrieved.value = true;
      fetchData();
    } 
  },
  { immediate: true }
);

watch(
  () => masterDataLoaded.value,
  () => {
    if (masterDataLoaded.value) {
      fetchData();
    }
  }
);

watch(
  () => selectedTabIndex.value,
  () => {
    selectNewIndex(selectedTabIndex.value);
  }
);

watch(
  () => selectedType.value,
  () => {
    console.log("InstallationDetails, watch selectedType, ", selectedType.value, dataMustBeRetrieved.value, masterDataLoaded.value);

    filterProductTypes(selectedType.value);
    filterControlTypes(selectedType.value);
    filterDetailTypes(selectedType.value);
    filterSubDetailTypes(selectedType.value);
    filterCollaborationTypes(selectedType.value);
    filterDeliveryTypes(selectedType.value);
    filterInputSources(selectedType.value);
  }
);

onMounted(() => {
  loadTypes();
  loadGroups();
  loadProductTypes();
  loadControlTypes();
  loadDetailTypes();
  loadSubDetailTypes();
  loadCollaborationTypes();
  loadDeliveryTypes();
  loadInputSources();
});

//hack voor vue 2.7
const proxy = getCurrentInstance().proxy;

async function fetchData() {
  if (dataMustBeRetrieved.value && masterDataLoaded.value) {
    const response = await getInstallationDetails(props.id);
    if (response && !response.error) {
      selectedTabIndex.value = setDetails(response.data);
      if (selectedTabIndex.value >= 0) {
        console.log("InstallationDetails, fetchData, response.data[selectedTabIndex.value]: ", response.data[selectedTabIndex.value])
        selectedType.value = response.data[selectedTabIndex.value].installationTypeKey;
      }
    } else {
      proxy.$toaster.error("Ophalen van de data is mislukt! " + response.error);
    }

    dataMustBeRetrieved.value = false;
  } else {
    selectedType.value = 'Onbekend'; // Dit triggert de verschillende filters op Installation.Type zodat niet de volledige lijsten (ongefiltert op type) getoond worden
  }
}

const obs = ref(null);

async function saveDetail() {
  isSaving.value = true;
  const valid = await obs.value.validate();
  if (valid) {
    let data = {};
    if (isEditTLMode.value) {
      data = { valFrom: selectedData.value.valFrom, valTill: selectedData.value.valTill, modDescr: selectedData.value.modDescr };
    } else {
      data = JSON.parse(JSON.stringify(selectedData.value));
      //datums mogen niet geupdate worden
      data.valFrom = null;
      data.valTill = null;
    }
    data.installationTypeKey = selectedType.value;

    const result = await patchInstallationDetails(selectedData.value.detailId, data);
    if (!result.error) {
      updateSelectedDetail();
      if (isEditTLMode.value) {
        // refresh de data i.v.m. gewijzigde tijdlijn.
        fetchData();
      }
      setReadonlyMode();
      proxy.$toaster.success("Gegevens van installatie zijn successvol gewijzigd.");
    } else {
      proxy.$toaster.error(result.error);
    }
  }
  isSaving.value = false; 
}

function addNewDetail() {
  if (selectedData.value) {
    addNewDetailMode();
  } else {
    proxy.$toaster.warning("Selecteer eerst een versie s.v.p..");
  }
}

async function saveNewDetail() {
  isSaving.value = true;
  const valid = await obs.value.validate();
  if (valid) {
    let data = {};
    data = JSON.parse(JSON.stringify(selectedData.value));
    data.locRef = props.locationReference;
    data.installationTypeKey = selectedType.value;
    const response = await postInstallationDetails(data);

    if (!response.error) {
      proxy.$toaster.success("Gegevens van deze installatie zijn successvol toegevoegd.");
      if (isAddingMasterMode.value) {
        navigateToLocation(props.locationReference);
      } else {
        setReadonlyMode();
        fetchData();
      }
    } else {
      proxy.$toaster.error(response.error);
    }
  }
  isSaving.value = false;
}

async function onCancelAdd() {
  if (isAddingMasterMode.value) {
    proxy.$toaster.warning(`Installatiegegevens toevoegen geannuleerd.`);
    navigateToLocation(props.locationReference);
  } else {
    proxy.$toaster.warning(`$Installatiegegevensversie toevoegen geannuleerd.`);
    setReadonlyMode();
    resetSelectedDetail();
  }
}

async function onCancel() {
  proxy.$toaster.warning(`bewerken geannuleerd.`);
  selectNewIndex(selectedTabIndex.value);
  requestAnimationFrame(() => {
    obs.value.reset();
  });
  setReadonlyMode();
}

async function loadGroups() {
  const response = await getInstallationGroups();
  if (response.severity <= severityCode.warning) {
    groups.value = _.sortBy([...response.data.installationGroups], "name");
    groupsLoaded.value = true;
  } else {
    proxy.$toaster.error("Ophalen van de installatie groups is mislukt! " + response.error);
  }
}

async function loadTypes() {
  const response = await getInstallationTypes();
  if (response.severity <= severityCode.warning) {
    types.value = _.sortBy([...response.data.installationTypes], "description");
    types.value.unshift({ description: "Onbekend", name: "Onbekend" });
    // console.log("InstallationDetails, loadTypes, types: ", types.value)
    typesLoaded.value = true;
  } else {
    proxy.$toaster.error("Ophalen van de installatie types is mislukt! " + response.error);
  }
}

async function loadProductTypes() {
  const response = await getInstallationProductTypes();
  if (response.severity <= severityCode.warning) {
    allProductTypes.value = _.sortBy([...response.data.installationProductTypes], "description");
    allProductTypes.value.unshift({ installationTypeKey: null, id: emptyGuid, description: "Onbekend", name: "Onbekend" });
    productTypes.value = allProductTypes.value;
    productTypesLoaded.value = true;
  } else {
    proxy.$toaster.error("Ophalen van de installatie product types is mislukt! " + response.error);
  }
}

async function filterProductTypes(key) {
  productTypes.value = _.filter(allProductTypes.value, function (productType) {
    return productType.installationTypeKey == key || productType.id == emptyGuid;
  });
}

async function loadControlTypes() {
  const response = await getInstallationControlTypes();
  if (response.severity <= severityCode.warning) {
    // console.log("InstallationDetails, loadControlTypes, ", response.data);
    allControlTypes.value = _.sortBy([...response.data.installationControlTypes], "name");
    allControlTypes.value.unshift({ InstallationTypeKey: null, id: emptyGuid, description: "Geen", name: "Geen" });
    controlTypes.value = allControlTypes.value;
    controlTypesLoaded.value = true;
  } else {
    proxy.$toaster.error("Ophalen van de installatie control types is mislukt! " + response.error);
  }
}

async function filterControlTypes(key) {
  controlTypes.value = _.filter(allControlTypes.value, (controlType) => {
    return controlType.installationTypeKey == key || controlType.id == emptyGuid;
  });
  // console.log("InstallationDetails, filterControlTypes, ", allControlTypes.value, id, controlTypes.value);
}

async function loadDetailTypes() {
  const response = await getInstallationDetailTypes();
  if (response.severity <= severityCode.warning) {
    allDetailTypes.value = _.sortBy([...response.data.installationNameDescriptions], "name");
    allDetailTypes.value.unshift({ installationTypeKey: null, id: emptyGuid, description: "Geen", name: "Geen" });
    detailTypes.value = allDetailTypes.value;
    detailTypesLoaded.value = true;
    // console.log("InstallationDetails, loadDetailTypes, ", detailTypes.value);
  } else {
    proxy.$toaster.error("Ophalen van de installatie detail types is mislukt! " + response.error);
  }
}

async function filterDetailTypes(key) {
  detailTypes.value = _.filter(allDetailTypes.value, function (detailTypes) {
    return detailTypes.installationTypeKey == key || detailTypes.id == emptyGuid;
  });
}

async function loadSubDetailTypes() {
  const response = await getInstallationSubDetailTypes();
  console.log("InstallationDetails, loadSubDetailTypes, ", response.data);
  if (response.severity <= severityCode.warning) {
    allSubDetailTypes.value = _.sortBy([...response.data.installationNameDescriptions], "name");
    allSubDetailTypes.value.unshift({ installationTypeKey: null, id: emptyGuid, description: "Geen", name: "Geen" });
    subDetailTypes.value = allSubDetailTypes.value;
    subDetailTypesLoaded.value = true;
  } else {
    proxy.$toaster.error("Ophalen van de installatie sub-detail types is mislukt! " + response.error);
  }
}

async function filterSubDetailTypes(key) {
  subDetailTypes.value = _.filter(allSubDetailTypes.value, function (item) {
    return item.installationTypeKey == key || item.id == emptyGuid;
  });
}

async function loadCollaborationTypes() {
  const response = await getInstallationCollaborationTypes();
  console.log("InstallationDetails, loadCollaborationTypes, ", response.data);
  if (response.severity <= severityCode.warning) {
    allCollaborationTypes.value = _.sortBy([...response.data.installationNameDescriptions], "name");
    allCollaborationTypes.value.unshift({ installationTypeKey: null, id: emptyGuid, description: "Geen", name: "Geen" });
    collaborationTypes.value = allCollaborationTypes.value;
    collaborationTypesLoaded.value = true;
  } else {
    proxy.$toaster.error("Ophalen van de installatie collaboration types is mislukt! " + response.error);
  }
}

async function filterCollaborationTypes(key) {
  collaborationTypes.value = _.filter(allCollaborationTypes.value, function (item) {
    return item.installationTypeKey == key || item.id == emptyGuid;
  });
}

async function loadDeliveryTypes() {
  const response = await getInstallationDeliveryTypes();
  if (response.severity <= severityCode.warning) {
    allDeliveryTypes.value = _.sortBy([...response.data.installationNameDescriptions], "name");
    allDeliveryTypes.value.unshift({ installationTypeKey: null, id: emptyGuid, description: "Geen", name: "Geen" });
    deliveryTypes.value = allDeliveryTypes.value;
    deliveryTypesLoaded.value = true;
  } else {
    proxy.$toaster.error("Ophalen van de installatie delivery types is mislukt! " + response.error);
  }
}

async function filterDeliveryTypes(key) {
  deliveryTypes.value = _.filter(allDeliveryTypes.value, function (deliveryTypes) {
    return deliveryTypes.installationTypeKey == key || deliveryTypes.id == emptyGuid;
  });
}

async function loadInputSources() {
  const response = await getInstallationInputSources();
  if (response.severity <= severityCode.warning) {
    allInputSources.value = _.sortBy([...response.data.installationNameDescriptions], "name");
    allInputSources.value.unshift({ installationTypeKey: null, id: emptyGuid, description: "Geen", name: "Geen" });
    inputSources.value = allInputSources.value;
    inputSourcesLoaded.value = true;
  } else {
    proxy.$toaster.error("Ophalen van de installatie input sources is mislukt! " + response.error);
  }
}

async function filterInputSources(key) {
  inputSources.value = _.filter(allInputSources.value, function (inputSources) {
    return inputSources.installationTypeKey == key || inputSources.id == emptyGuid;
  });
}
</script>

<template>
  <v-container fluid pa-0>
    <header class="app-welcome-message">
      <h2 v-if="!isAddingMasterMode && !isAddingDetailMode">Details van installatiegegevens</h2>
      <h2 v-if="isAddingMasterMode">Toevoegen van installatiegegevens</h2>
      <h2 v-if="isAddingDetailMode">Toevoegen van installatiegegevensversie</h2>
    </header>

    <v-row>
      <v-col>
        <v-container fluid>
          <v-row align="center">
            <v-col v-if="hasDetails" cols="auto"> Tijdlijn: </v-col>
            <v-col>
              <v-btn-toggle v-model="selectedTabIndex" group mandatory color="blue accent-3">
                <v-btn class="v-application elevation-2" v-for="(item, index) in detailTabs()" :key="index" :value="index" :disabled="!isReadOnly"> {{ item }} </v-btn>
              </v-btn-toggle>
            </v-col>
          </v-row>
        </v-container>
        <ValidationObserver ref="obs">
          <v-form>
            <masonry :cols="{ default: 2, 1264: 1 }" :gutter="12">
              <v-container class="boxed" fluid :class="{ noBorders: !isEditTLMode && !isAddingMasterMode && !isAddingDetailMode }">
                <header>Geldigheid</header>
                <v-row>
                  <v-col>
                    <ValidationProvider name="Geldig vanaf" rules="date_between|required" v-slot="{ errors }" :mode="checkDateValid">
                      <date-input
                        v-model="selectedData.valFrom"
                        persistent-placeholder
                        label="Geldig vanaf"
                        :errors="errors"
                        :class="{ noBorders: !allowEditTL }"
                        :outlined="allowEditTL"
                        :readonly="!allowEditTL"
                        rules="required"
                      ></date-input>
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <ValidationProvider name="Geldig tot" :rules="`date_between|date_after:${selectedData.valFrom}`" v-slot="{ errors }" :mode="checkDateValid">
                      <date-input
                        v-model="selectedData.valTill"
                        persistent-placeholder
                        label="Geldig tot"
                        :errors="errors"
                        :class="{ noBorders: !allowEditTL }"
                        :outlined="allowEditTL"
                        :readonly="!allowEditTL"
                      ></date-input>
                    </ValidationProvider>
                  </v-col>
                </v-row>
              </v-container>

              <v-container class="boxed" fluid :class="{ noBorders: !isAddingMasterMode && !isAddingDetailMode }">
                <header>Aanpassing</header>
                <v-row>
                  <v-col>
                    <ValidationProvider name="Aanpassings omschrijving" rules="required" v-slot="{ errors }">
                      <v-text-field
                        hide-details="auto"
                        persistent-placeholder
                        label="Omschrijving"
                        v-model="selectedData.modDescr"
                        :error-messages="errors"
                        :class="{ noBorders: !allowEdit && !allowEditTL }"
                        :outlined="allowEdit || allowEditTL"
                        :readonly="!allowEdit && !allowEditTL"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row v-if="!isAddingMasterMode && !isAddingDetailMode">
                  <v-col>
                    <v-text-field hide-details="auto" label="Door" persistent-placeholder v-model="selectedData.modBy" readonly class="noBorders"></v-text-field>
                  </v-col>
                </v-row>
                <v-row v-if="!isAddingMasterMode && !isAddingDetailMode">
                  <v-col>
                    <v-text-field hide-details="auto" label="Datum" persistent-placeholder v-model="modDateFormatted" readonly class="noBorders"></v-text-field>
                  </v-col>
                </v-row>
              </v-container>

              <v-container class="boxed" fluid :class="{ noBorders: !isAddingMasterMode }">
                <header>Installatie definitie</header>
                <v-row>
                  <v-col>
                    <ValidationProvider name="Type" rules="unequal_Onbekend" v-slot="{ errors }">
                      <v-select
                        hide-details="auto"
                        label="Type"
                        persistent-placeholder
                        :items="types"
                        item-text="description"
                        item-value="key"
                        v-model="selectedType"
                        :error-messages="errors"
                        :outlined="isAddingMasterMode"
                        :class="{ noBorders: !isAddingMasterMode }"
                        :readonly="!isAddingMasterMode"
                      ></v-select>
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <ValidationProvider name="Referentie" rules="required|max:40" v-slot="{ errors, valid }">
                      <v-text-field
                        hide-details="auto"
                        label="Referentie"
                        persistent-placeholder
                        :counter="40"
                        v-model="selectedData.ref"
                        :error-messages="errors"
                        :success="valid"
                        :outlined="isAddingMasterMode"
                        :class="{ noBorders: !isAddingMasterMode }"
                        :readonly="!isAddingMasterMode"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <ValidationProvider name="Groep" rules="required" v-slot="{ errors }">
                      <v-select
                        hide-details="auto"
                        label="Groep"
                        persistent-placeholder
                        :items="groups"
                        item-text="ref"
                        item-value="id"
                        v-model="selectedData.groupId"
                        :error-messages="errors"
                        :outlined="isAddingMasterMode"
                        :class="{ noBorders: !isAddingMasterMode }"
                        :readonly="!isAddingMasterMode"
                      ></v-select>
                    </ValidationProvider>
                  </v-col>
                </v-row>
              </v-container>
            </masonry>

            <masonry :cols="{ default: 3, 1264: 2, 768: 1 }" :gutter="12">
              <v-container class="boxed" fluid>
                <header>Kenmerken</header>
                <v-row>
                  <v-col>
                    <ValidationProvider name="Constructiedatum" v-slot="{ errors }" :mode="checkDateValid">
                      <date-input
                        :value="selectedData.constrDate"
                        @input="selectedData.constrDate = $event !== '' ? $event : undefined"
                        label="Constructiedatum"
                        persistent-placeholder
                        :errors="errors"
                        :outlined="allowEdit"
                        :class="{ noBorders: !allowEdit }"
                        :readonly="!allowEdit"
                        rules="required"
                      ></date-input>
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <ValidationProvider name="Merk" rules="max:100" v-slot="{ errors, valid }">
                      <v-text-field
                        hide-details="auto"
                        label="Merk"
                        persistent-placeholder
                        :counter="100"
                        v-model="selectedData.brand"
                        :error-messages="errors"
                        :success="valid"
                        :outlined="allowEdit"
                        :class="{ noBorders: !allowEdit }"
                        :readonly="!allowEdit"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <ValidationProvider name="Model" rules="max:50" v-slot="{ errors, valid }">
                      <v-text-field
                        hide-details="auto"
                        label="Model"
                        persistent-placeholder
                        :counter="50"
                        v-model="selectedData.model"
                        :error-messages="errors"
                        :success="valid"
                        :outlined="allowEdit"
                        :class="{ noBorders: !allowEdit }"
                        :readonly="!allowEdit"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-select
                      hide-details="auto"
                      label="ProductType"
                      persistent-placeholder
                      :items="productTypes"
                      item-text="description"
                      item-value="id"
                      v-model="selectedData.productTypeId"
                      :outlined="allowEdit"
                      :class="{ noBorders: !allowEdit }"
                      :readonly="!allowEdit"
                      :placeholder="placeholderType"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-select
                      hide-details="auto"
                      label="ControlType"
                      persistent-placeholder
                      :items="controlTypes"
                      item-text="name"
                      item-value="id"
                      v-model="selectedData.controlTypeId"
                      :outlined="allowEdit"
                      :class="{ noBorders: !allowEdit }"
                      :readonly="!allowEdit"
                      :placeholder="placeholderType"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-select
                      hide-details="auto"
                      label="Detail type"
                      persistent-placeholder
                      :items="detailTypes"
                      item-text="description"
                      item-value="id"
                      v-model="selectedData.detailTypeId"
                      :outlined="allowEdit"
                      :class="{ noBorders: !allowEdit }"
                      :readonly="!allowEdit"
                      :placeholder="placeholderType"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-select
                      hide-details="auto"
                      label="Sub-Detail type"
                      persistent-placeholder
                      :items="subDetailTypes"
                      item-text="description"
                      item-value="id"
                      v-model="selectedData.subDetailTypeId"
                      :outlined="allowEdit"
                      :class="{ noBorders: !allowEdit }"
                      :readonly="!allowEdit"
                      :placeholder="placeholderType"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-select
                      hide-details="auto"
                      label="Samenwerkings type"
                      persistent-placeholder
                      :items="collaborationTypes"
                      item-text="description"
                      item-value="id"
                      v-model="selectedData.collaborationTypeId"
                      :outlined="allowEdit"
                      :class="{ noBorders: !allowEdit }"
                      :readonly="!allowEdit"
                      :placeholder="placeholderType"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-select
                      hide-details="auto"
                      label="Afgifte systeem"
                      persistent-placeholder
                      :items="deliveryTypes"
                      item-text="description"
                      item-value="id"
                      v-model="selectedData.deliveryTypeId"
                      :outlined="allowEdit"
                      :class="{ noBorders: !allowEdit }"
                      :readonly="!allowEdit"
                      :placeholder="placeholderType"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-select
                      hide-details="auto"
                      label="Aanvoerbron"
                      persistent-placeholder
                      :items="inputSources"
                      item-text="description"
                      item-value="id"
                      v-model="selectedData.inputSourceId"
                      :outlined="allowEdit"
                      :class="{ noBorders: !allowEdit }"
                      :readonly="!allowEdit"
                      :placeholder="placeholderType"
                    ></v-select>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <ValidationProvider name="InputValue" rules="max:50" v-slot="{ errors, valid }">
                      <v-text-field
                        hide-details="auto"
                        label="Aanvoerwaarde"
                        persistent-placeholder
                        :counter="50"
                        v-model="selectedData.inputValue"
                        :error-messages="errors"
                        :success="valid"
                        :outlined="allowEdit"
                        :class="{ noBorders: !allowEdit }"
                        :readonly="!allowEdit"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <ValidationProvider name="Surface" rules="decimal:2|between:0,9999999.99|min_value:1" v-slot="{ errors }">
                      <v-text-field
                        type="number"
                        hide-details="auto"
                        label="Oppervlakte"
                        persistent-placeholder
                        :value="selectedData.surface"
                        @input="selectedData.surface = $event !== '' ? $event : undefined"
                        :error-messages="errors"
                        :outlined="allowEdit"
                        :class="{ noBorders: !allowEdit }"
                        :readonly="!allowEdit"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <ValidationProvider name="Aantal" rules="decimal:2|between:0,9999999.99|min_value:1" v-slot="{ errors }">
                      <v-text-field
                        type="number"
                        hide-details="auto"
                        label="Aantal"
                        persistent-placeholder
                        :value="selectedData.qty"
                        @input="selectedData.qty = $event !== '' ? $event : undefined"
                        :error-messages="errors"
                        :outlined="allowEdit"
                        :class="{ noBorders: !allowEdit }"
                        :readonly="!allowEdit"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
              </v-container>

              <v-container class="boxed" fluid>
                <header>Verbruiken</header>
                <v-row>
                  <v-col>
                    <ValidationProvider name="Elektra" rules="decimal:4|between:0,99999999999999.9999" v-slot="{ errors }">
                      <v-text-field
                        type="number"
                        hide-details="auto"
                        persistent-placeholder
                        label="Elektra"
                        :value="selectedData.consElk"
                        @input="selectedData.consElk = $event !== '' ? $event : undefined"
                        :error-messages="errors"
                        :outlined="allowEdit"
                        :class="{ noBorders: !allowEdit }"
                        :readonly="!allowEdit"
                      >
                        <template slot="append">kWh/jaar</template>
                      </v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <ValidationProvider name="Gas" rules="decimal:4|between:0,99999999999999.9999" v-slot="{ errors }">
                      <v-text-field
                        type="number"
                        hide-details="auto"
                        label="Gas"
                        persistent-placeholder
                        :value="selectedData.consGas"
                        @input="selectedData.consGas = $event !== '' ? $event : undefined"
                        :error-messages="errors"
                        :outlined="allowEdit"
                        :class="{ noBorders: !allowEdit }"
                        :readonly="!allowEdit"
                      >
                        <template slot="append">Nm³/jaar</template>
                      </v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <ValidationProvider name="Warmte" rules="decimal:4|between:0,99999999999999.9999" v-slot="{ errors }">
                      <v-text-field
                        type="number"
                        hide-details="auto"
                        label="Warmte"
                        persistent-placeholder
                        :value="selectedData.consHeat"
                        @input="selectedData.consHeat = $event !== '' ? $event : undefined"
                        :error-messages="errors"
                        :outlined="allowEdit"
                        :class="{ noBorders: !allowEdit }"
                        :readonly="!allowEdit"
                      >
                        <template slot="append">GJ/jaar</template>
                      </v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <ValidationProvider name="Koude" rules="decimal:4|between:0,99999999999999.9999" v-slot="{ errors }">
                      <v-text-field
                        type="number"
                        hide-details="auto"
                        label="Koude"
                        persistent-placeholder
                        :value="selectedData.consCold"
                        @input="selectedData.consCold = $event !== '' ? $event : undefined"
                        :error-messages="errors"
                        :outlined="allowEdit"
                        :class="{ noBorders: !allowEdit }"
                        :readonly="!allowEdit"
                      >
                        <template slot="append">GJ/jaar</template>
                      </v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <ValidationProvider name="consOther" rules="decimal:4|between:0,99999999999999.9999" v-slot="{ errors }">
                      <v-text-field
                        type="number"
                        hide-details="auto"
                        label="Anders"
                        persistent-placeholder
                        :value="selectedData.consOther"
                        @input="selectedData.consOther = $event !== '' ? $event : undefined"
                        :error-messages="errors"
                        :outlined="allowEdit"
                        :class="{ noBorders: !allowEdit }"
                        :readonly="!allowEdit"
                      >
                        <!-- <template slot="append">???/?????</template> -->
                      </v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
              </v-container>
              <v-container class="boxed" fluid>
                <header>Output</header>
                <v-row>
                  <v-col>
                    <ValidationProvider name="Piek" rules="decimal:4|between:0,99999999999999.9999" v-slot="{ errors }">
                      <v-text-field
                        type="number"
                        hide-details="auto"
                        label="Piek"
                        persistent-placeholder
                        :value="selectedData.outPeak"
                        @input="selectedData.outPeak = $event !== '' ? $event : undefined"
                        :error-messages="errors"
                        :outlined="allowEdit"
                        :class="{ noBorders: !allowEdit }"
                        :readonly="!allowEdit"
                      >
                        <!-- <template slot="append">???/?????</template> -->
                      </v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <ValidationProvider name="Gemiddeld" rules="decimal:4|between:0,99999999999999.9999" v-slot="{ errors }">
                      <v-text-field
                        type="number"
                        hide-details="auto"
                        label="Gemiddeld"
                        persistent-placeholder
                        :value="selectedData.outAvg"
                        @input="selectedData.outAvg = $event !== '' ? $event : undefined"
                        :error-messages="errors"
                        :outlined="allowEdit"
                        :class="{ noBorders: !allowEdit }"
                        :readonly="!allowEdit"
                      >
                        <!-- <template slot="append">???/?????</template> -->
                      </v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
              </v-container>
              <div></div>
              <v-container class="boxed" fluid>
                <!-- <v-container v-if="showPVFields"> -->
                <header>Ligging PV installatie</header>
                <v-row>
                  <v-col>
                    <ValidationProvider name="Azimut" rules="decimal:4|between:0,360" v-slot="{ errors }">
                      <v-text-field
                        type="number"
                        hide-details="auto"
                        label="Azimut"
                        persistent-placeholder
                        :value="selectedData.azimut"
                        @input="selectedData.azimut = $event !== '' ? $event : undefined"
                        :error-messages="errors"
                        :outlined="allowEdit"
                        :class="{ noBorders: !allowEdit }"
                        :readonly="!allowEdit"
                      >
                        <template slot="append">graden</template>
                      </v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <ValidationProvider name="Helling" rules="decimal:4|between:-90,90" v-slot="{ errors }">
                      <v-text-field
                        type="number"
                        hide-details="auto"
                        label="Helling"
                        persistent-placeholder
                        :value="selectedData.incl"
                        @input="selectedData.incl = $event !== '' ? $event : undefined"
                        :error-messages="errors"
                        :outlined="allowEdit"
                        :class="{ noBorders: !allowEdit }"
                        :readonly="!allowEdit"
                      >
                        <template slot="append">graden</template>
                      </v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
              </v-container>
            </masonry>

            <details-buttons
              :addMode="isAddingMode"
              :editMode="!isReadOnly"
              :allowSave="!isSaving"
              @edit="setEditMode"
              @edit-tl="setEditTLMode"
              @on-cancel="onCancel"
              @add-new-detail="addNewDetail"
              @on-cancel-add="onCancelAdd"
              @save-detail="saveDetail"
              @save-new-detail="saveNewDetail"
            />
          </v-form>
        </ValidationObserver>
      </v-col>
    </v-row>
  </v-container>
</template>
