import { apiClient } from "./httpClient";

const END_POINT_LOC = "location";
const END_POINT_INS = "installation";

// you can pass arguments to use as request parameters/data
// const getInstallation = async (buidlingId) => await apiClient.get(END_POINT, { buidlingId });

const getInstallationsOverview = async (reference, currentPage, pageSize, sortBy, sortDesc, filterData) => {
  let errorResponse = undefined;
  const response = await apiClient
    .post(`${END_POINT_LOC}/${reference}/installationsoverview`, { filterData: filterData }, { params: { pageNumber: currentPage, pageSize: pageSize, sortBy: sortBy, sortDesc: sortDesc } })
    .catch((error) => {
      errorResponse = error.toString();
    });
  return { data: response ? response.data : undefined, error: errorResponse };
};

const getInstallations = async (currentPage, fetchDataOrigin, filterData) => {
  const response = await apiClient.post(`${END_POINT_INS}/list`, { ...filterData }, { params: { page: currentPage, "event-origin": fetchDataOrigin } });
  return response;
};

const getInstallationFilterOptions = async (installationId, fieldName, text) => {
  return await apiClient.get(`${END_POINT_INS}/AutoComplete`, { params: { installationId: installationId, fieldname: fieldName, text: text } });
};

const getInstallation = async (installationId) => {
  let errorResponse = null;
  let response = await apiClient.get(`${END_POINT_INS}/details`, { params: { installationId: installationId } }).catch((error) => {
    errorResponse = error.toString();
  });
  if (errorResponse) {
    response = "";
  }
  return { data: response.data, error: errorResponse };
};

const getInstallationDetails = async (installationId) => {
  let errorResponse = undefined;
  let response = await apiClient.get(`${END_POINT_INS}/${installationId}/details`).catch((error) => {
    errorResponse = error.toString();
  });
  return { data: response ? response.data : undefined, error: errorResponse };
};

const patchInstallationDetails = async (installationDetailId, data) => {
  let errorResponse = undefined;
  let apiError = undefined;
  const response = await apiClient.patch(`${END_POINT_INS}/${installationDetailId}`, { ...data }).catch((error) => {
    apiError = error;
  });

  if (apiError != undefined) {
    switch (apiError.response?.status) {
      // todo voor codes moet const httpConstants = require('http2').constants gebruikt worden maar krijg het nog niet werkend
      case 409: // conflict
        errorResponse = "Er is een conflict op de tijdlijn";
        break;
      default:
        errorResponse = apiError.toString();
    }
  } else {
    switch (response.status) {
      case 204: // no-content
        errorResponse = "Er zijn geen gegevens geupdate";
        break;
    }
  }

  return { data: response ? response.data : undefined, error: errorResponse };
};

const postInstallationDetails = async (data) => {
  let errorResponse = undefined;
  let response = await apiClient.post(`${END_POINT_INS}`, { ...data }).catch((error) => {
    switch (error.response?.status) {
      // todo voor codes moet const httpConstants = require('http2').constants gebruikt worden maar krijg het nog niet werkend
      case 409: // conflict
        errorResponse = "Er is een conflict op de tijdlijn";
        break;
      default:
        errorResponse = error.toString();
    }
  });
  if (response) {
    switch (response.status) {
      // todo voor codes moet const httpConstants = require('http2').constants gebruikt worden maar krijg het nog niet werkend
      case 204: // no-content
        errorResponse = "Er zijn geen gegevens geinsert";
        break;
      default:
    }
  }
  return { data: response ? response.data : undefined, error: errorResponse };
};

export { getInstallations, getInstallationFilterOptions, getInstallation, getInstallationsOverview, getInstallationDetails, patchInstallationDetails, postInstallationDetails };
